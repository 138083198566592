*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
html,
#root {
  height: 100%;
  font-family: RedHatText;
}

body {
  margin: 0;
  width: 100%;
  font-family: RedHatText, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

#root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.uploadcare--widget__button,
.uploadcare--widget__dragndrop-area,
.uploadcare--progress,
.uploadcare--widget__text {
  display: none;
}

@font-face {
  font-family: 'RedHatText';
  font-weight: 400;
  src: local('RedHatText'), url(./fonts/RedHatText-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'RedHatText';
  font-weight: 500;
  src: local('RedHatText'), url(./fonts/RedHatText-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'RedHatText';
  font-weight: 700;
  src: local('RedHatText'), url(./fonts/RedHatText-Bold.ttf) format('truetype');
}
